<template>
  <div class="event-single">
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{ event.name }}
          </h1>
          <h2 class="subtitle ">
            {{ event.description }}
            <br>
            {{ event.short }}
          </h2>
        </div>
      </div>
    </section>
    
    <section class="event-content">
      <div class="container">
        <p class="is-size-4 description">{{ event.long }}</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'EventSingle',
  data () {
    return {
      events: this.$eventData,
      event: {}
    }
  },
  created() {
    const ID = Number(this.$route.params.id);
    let event = this.events.find(event => event.id === ID);
    this.event = event;
  }
}
</script>

<style lang="scss" scoped>
  .event-single {
    margin-top: 30px;
  }
  .hero {
    margin-bottom: 70px;
  }
  .event-images {
    margin-top: 50px;
  }
  .description {
    margin-bottom: 30px;
  }
</style>

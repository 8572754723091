// ...
import 'bulma/css/bulma.css';
// ...

import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

Vue.prototype.$eventData = [
  {
    id: 1,
    name: 'Create your Peloton',
    description: 'Like a Peloton in Cycling, you create your own Peloton.',
    short: 'xxx',
    long: 'xxx',
    featuredImage: 'https://farm4.staticflickr.com/3244/2738488282_4b1bf04220_z.jpg'
  },
  {
    id: 2,
    name: 'Compete with others.',
    description: 'Create your Peloton and compete with each other',
    short: 'xxx',
    long: 'xxx',
    featuredImage: 'https://live.staticflickr.com/3564/3502466761_931872bc91_z.jpg'
  },
  {
     id: 3,
     name: 'Live Data',
     description: 'Show off your efforts',
     short: 'xxx',
     long: "xx",
     featuredImage: 'https://live.staticflickr.com/3373/3506180724_66b789fdb5_z.jpg'
  },
]

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="events container">
    <h2 class="subtitle is-3">
    Peloton Live Components
    </h2>
    <div class="columns is-multiline is-centered">
      <div v-for="event in events" :event="event" :key="event.id" class="column is-one-quarter">
      <EventCard :event="event" />
      </div>
    </div>
  </div>
</template>


<script>
import EventCard from '@/components/EventCard';
export default {
  name: 'EventsList',
  components : {
    EventCard
  },
  data () {
    return {
      event: {},
      events: this.$eventData
    }
  }
}
</script>

<style lang="scss" scoped>
  .events {
    margin-top: 100px;
    text-align: center;
  }
</style>
